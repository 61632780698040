* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html  {
  @media(max-width: 1080px) {
    font-size: 93.75%;
  }
  @media(max-width: 720px) {
    font-size: 87.5%
  }
}

body {
  background: #EBEBEB;
  -webkit-font-smoothing: antialiased;
}

.title{
  font-family: 'Reserva1';
  margin-bottom: 1rem;
}
.subtitle{
  font-family: 'Reserva1';
  font-style: italic;
  margin-bottom: 1rem;
}
.m-6{
  margin-top: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 740px;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
  padding: 1rem;
}

.container header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 1.5rem;
}

.container header img {
  width: 25%;
}

.container main {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container main input {
  padding: 1rem;
  border: 0;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #707070;
}

.inline{
  width: 100%;
  margin: 15px 0;
  height: 1%;
  border-top: 2px solid #9d9d9d;
}

.content {
  flex-direction: column;
  display: flex;
  overflow: auto;
  min-height: 45%;
  background-color: #FFF;
  padding: 2rem;
  border-radius: 5px;
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  width: 100%;
}
@font-face {
  font-family: "Reserva1";
  src: url("https://produtos-digitais.usereserva.com/fonts/ReservaSans-Bold.woff") format("woff");
}
@font-face {
  font-family: "Reserva2";
  src: url("https://produtos-digitais.usereserva.com/fonts/ReservaSerif-Bold.woff2") format("woff2");
}

.form button {
  padding: 1rem;
  border-radius: 5px;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: #ffffff;
  background: #212121;
}

.siteBtn{
  padding: 1rem;
  border-radius: 5px;
  border: 0;
  width: 20%;
  margin: 0 auto;
  outline: 0;
  cursor: pointer;
  color: #ffffff;
  background: #212121;
  text-decoration: none;
  font-family: 'Reserva1';
}

.order {
  display: flex;
  flex-direction: column;
}

.order span{
  font-family: 'Reserva2';
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 2rem;
}